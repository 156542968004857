import React from 'react';
import './style.scss';

const TalkPart = ({ header, paragraph }) => {
   return (
      <section className="talk-part">
          <h2 className="talk-part__h2">{header}</h2>
          <p className="talk-part__p">{paragraph}</p>
      </section>
   )
}

export default TalkPart;
 