import React, { useState, useMemo, useRef } from 'react';
import { useWindowSize } from 'utils.js';
import './style.scss';
import { useEffect } from 'react';
import { Separator } from 'components';

const Content = ({ locationId, scrollRef, children }) => {
   const size = useWindowSize();
   const viewRef = useRef();

   const offsetLeft = useMemo(() => locationId * size.width, [locationId, size]);

   return (
      <section ref={scrollRef} className="content" style={{ left: `${offsetLeft}px` }}>
         {children}
         {/* <footer className="content__footer">
            <h1 className="footer__h1">Daud LeGrand</h1>
            <Separator maxWidth={812} marginTop={24} marginBottom={22} />
            <nav className="footer__nav">
               <a style={{ pointerEvents: 'none' }}>® Daud le Grand 2020</a>
               <div className="footer__container">
                  <a href="#">Terms</a>
                  <a href="#" style={{ marginLeft: '9rem' }}>Privacy Policy</a>
               </div>
            </nav>
         </footer> */}
      </section>
   )
}

export default Content;
