import React from 'react';
import './style.scss';

const Separator = ({ maxWidth, marginTop, marginBottom, id }) => {
   return (
      <div className="separator" id={id} style={{maxWidth: `${maxWidth}px`, marginTop: `${marginTop}px`, marginBottom: `${marginBottom}px`}}>
          <i className="separator__icon"></i>
          <hr className="separator__line"/>
          <hr className="separator__line"/>
      </div>
   )
}

export default Separator;
