
import { createStore } from 'redux';

const rootReducer = (state = [], action) => {
    const { data, type } = action;
    switch (type) {
        case "LOAD_DATA":
            return {
                ...state,
                data
            }
        default:
            return { ...state }
    }
}

const loadData = data => ({
    type: "LOAD_DATA",
    data
});

const store = createStore(rootReducer);

export { loadData };
export default store;