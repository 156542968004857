import React from 'react';
import './style.scss';

const Mail = ({}) => {
   return (
      <section className="mail">
          {/* <input className="mail__input" type="mail" placeholder="Your Email"/> */}
          <a className="mail__button" href="mailto:solutions@daudlegrand.com">Send Mail</a>  
      </section>
   )
}

export default Mail;
