import React, { useState } from 'react';
import './style.scss';

import classNames from 'classnames';

let selectorHidden = false;

const Header = ({ selectedTab, changeSelectedTab }) => {
    const [menuState, setMenuState] = useState(0);
    const selectorClass = classNames(
        'header__selector',
        `header__selector--stage-${selectedTab}`
    );

    const handleNavSelect = id => {
        console.log('s', id)
        changeSelectedTab(id);
        setMenuState(0);
    }

    const handleMenuClick = () => {
        menuState === 0 ? setMenuState(1) : setMenuState(0);
    }

    return (
        <header className="header">
            <div className="header__main" onClick={() => changeSelectedTab(0)}>
                <i className="header__main-icon"></i>
                <h1 className="header__main-text">Daud LeGrand</h1>
            </div>
            <nav className={menuState === 1 ? `header__nav header__nav--visible` : `header__nav`} onClick={menuState === 1 ? () => setMenuState(0) : () => { }}>
                <a href="#" onClick={() => handleNavSelect(0)} className={`header__link ${selectedTab === 0 ? 'header__link--selected' : ''}`}>About</a>
                <a href="#" onClick={() => handleNavSelect(1)} className={`header__link ${selectedTab === 1 ? 'header__link--selected' : ''}`}>Talk</a>
                <a href="#" onClick={() => handleNavSelect(2)} className={`header__link ${selectedTab === 2 ? 'header__link--selected' : ''}`}>Contact</a>
                <i className={selectorClass}></i>
            </nav>
            <i className={`header__icon-menu ${menuState === 1 ? 'header__icon-menu--open' : ''}`} onClick={handleMenuClick}>
                <span></span>
                <span></span>
            </i>
        </header>
    )
}

export default Header;